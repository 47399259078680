
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomSwitch from '@/components/atoms/AtomSwitch.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomNotification from '@/components/atoms/AtomNotification.vue';
import MoleculeRangeSlider from '@/components/molecules/range-slider/MoleculeRangeSlider.vue';
import OrganismPayPalSetup, { MODAL_ID as PaymentModalId } from '@/components/organisms/payment/OrganismPayPalSetup.vue';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import GenderEnum from '@/enums/settings/GenderEnum';
import PaymentTypesEnum from '@/enums/payment/PaymentTypesEnum';

import { hasFeature } from '@/services/feature/FeatureService';

export default Vue.extend({
  name: 'OrganismExtras',
  data: () => ({
    PaymentTypesEnum,
    GenderEnum,
    RolesEnum,
    FeatureEnum,
    PaymentModalId,
  }),
  components: {
    AtomCheckbox,
    AtomSwitch,
    AtomText,
    AtomNotification,
    MoleculeRangeSlider,
    OrganismPayPalSetup,
  },
  methods: {
    ...mapActions('rideIntent', [
      'selectRole',
      'setBusinessTrip',
      'setLadiesOnly',
      'setOffersPayment',
      'setClaimsPayment',
      'setOrganizationId',
      'setDetour',
      'setDefaultDetour',
    ]),
    hasFeature,
    isPaymentEnabled(role: RolesEnum): boolean {
      switch (role) {
        case RolesEnum.DRIVER:
          return this.getOtherDetails.claimsPayment;
        case RolesEnum.PASSENGER:
          return this.getOtherDetails.offersPayment;
        case RolesEnum.DRIVER_AND_PASSENGER:
        default:
          return this.getOtherDetails.claimsPayment && this.getOtherDetails.offersPayment;
      }
    },
    togglePayment(role: RolesEnum, status: boolean): void {
      switch (role) {
        case RolesEnum.DRIVER:
          this.setClaimsPayment(status);
          break;
        case RolesEnum.PASSENGER:
          this.setOffersPayment(status);
          break;
        case RolesEnum.DRIVER_AND_PASSENGER:
        default:
          this.setClaimsPayment(status);
          this.setOffersPayment(status);
      }

      if (status && this.getOtherDetails.businessTrip) {
        this.setBusinessTrip(false);
      }
    },
    handleBussinesTrip(role: RolesEnum, status: boolean): void {
      this.setBusinessTrip(status);

      if (status && this.isPaymentEnabled(role)) {
        this.setClaimsPayment(false);
        this.setOffersPayment(false);
      }
    },
  },
  computed: {
    ...mapGetters('rideIntent', ['getOtherDetails', 'getDetour', 'isEditMode', 'getSelectedRole', 'isPoolCar']),
    ...mapGetters('user', ['gender', 'features', 'hasPaymentTypeEnabled', 'hasPaypalEmail']),
  },
  beforeMount() {
    if (!this.getDetour) this.setDefaultDetour();
  },
});
