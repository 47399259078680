
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import AtomLink from '@/components/atoms/AtomLink.vue';

import { updateUser } from '@/api/user/userApi';

export const MODAL_ID = 'settings-paypal-email';

export default Vue.extend({
  name: 'OrganismPayPalSetup',
  components: {
    AtomTextInput,
    AtomButton,
    AtomText,
    MoleculeModal,
    AtomLink,
  },
  data() {
    return {
      MODAL_ID,
      ButtonVariant,
      ToastVariants,
      payPalEmail: '',
      loading: false,
      payPalValid: false,
      payPalMeRequirements: [{
        validator: (input) => /^[a-zA-Z0-9]*$/.test(input) && input.length <= 20,
        showOnValid: false,
        description: this.$t('settings.account.payment.modal.requirements').toString(),
        formatDescriptionAsHTML: true,
      }],
    };
  },
  computed: {
    ...mapGetters('user', [
      'getUserId',
      'firstName',
      'logonName',
      'lastName',
      'displayName',
      'phoneNumber',
      'rideSettings',
      'getPaypalEmail',
    ]),
  },
  methods: {
    ...mapActions('user', ['setPayment']),
    async finish() {
      this.loading = true;
      const { firstName, lastName, displayName, getUserId: userId, phoneNumber, payPalEmail } = this;
      const { status, data } = await updateUser({
        userId,
        firstName,
        lastName,
        displayName,
        rideSettings: {
          maxDetourMinutes: this.rideSettings.maxDetourMinutes,
          minSharedDurationPercentage: this.rideSettings.minSharedDurationPercentage,
          role: this.rideSettings.role,
        },
        payment: {
          paypalDonation: {
            username: payPalEmail,
          },
        },
        sms: !phoneNumber ? null : {
          detail: phoneNumber,
          usageTypes: ['ContactVerification', 'Help', 'Unsubscribe', 'RideCreatedUpdatedCancelled'],
        },
      });

      if (status === 200 && data) {
        this.$bvModal.hide(MODAL_ID);
        this.setPayment(data.payment);
        toast(this.$bvToast, this.$t('settings.account.payment.messages.success').toString());
      } else {
        toast(this.$bvToast, this.$t('settings.account.payment.messages.error').toString(), ToastVariants.DANGER);
      }
      this.loading = false;
    },
    setPayPalEmail(value: string): void {
      this.payPalEmail = value;
    },
  },
});
